import { AttributeItem, DocumentItem, MetadataItem } from "../models/Chart";
import { UrlLink } from "../models/Cluster";

export type ClusterRelated = {
  x: number;
  y: number;
  xLabel: string;
  yLabel: string;
  relatedWords: string[];
  relatedLinks: UrlLink[];
};

export type ClusterRelatedKeyword = {
  keyword: string;
  links: UrlLink[];
};

export type XDatum = {
  x: number;
  y: number;
  searchedNum: number;
  searchedNumRatio: number;
  totalDocNum: number;
};

export type PeriodDetail = {
  x: number;
  mostRelatedWords: string[];
  links: UrlLink[];
};

export type MetadataState =
  | {
      metadatas: Array<MetadataItem>;
      data: MetadataItem;
      x: MetadataItem;
      y: MetadataItem;
    }
  | undefined;

export enum Tab {
  NONE,
  CLUSTER_ALL,
  CLUSTER_DATUM,
}

export type ClusterState = {
  xTicks: Map<string, string>;
  yTicks: Map<string, string>;
  clusters: Array<ClusterDatum>;
  documentLinks: Array<DocumentItem>;
  query: string;
  tab: Tab;
};

export type ClusterDatum = {
  id: string;
  title: string;
  xValue: string;
  yValue: string;
  x: number;
  y: number;
  size: number;
  docCount: number;
};

export const CLUSTER_STATE_DEFAULT: ClusterState = {
  xTicks: new Map(),
  yTicks: new Map(),
  clusters: [],
  documentLinks: [],
  query: "",
  tab: Tab.NONE,
};

export type ClusterDatumState =
  | {
      datum: ClusterDatum;
      query: string | undefined;
      documentLinks: Array<DocumentItem>;
      attributes: Array<AttributeItem>;
    }
  | undefined;

export type RatioState = {
  xTicks: Map<string, string>;
  ratios: Array<RatioDatum>;
  query: string;
};

export type RatioDatum = {
  id: string;
  x: string;
  y: number;
  ratio: number;
};

export const RATIO_STATE_DEFAULT: RatioState = {
  xTicks: new Map(),
  ratios: [],
  query: "",
};

export type RatioDatumState =
  | {
      datum: RatioDatum;
      query: string;
      documentLinks: Array<DocumentItem>;
      attributes: Array<AttributeItem>;
    }
  | undefined;

export type LlmState =
  | {
      message: string;
    }
  | undefined;
