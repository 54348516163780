import "./Chart.scss";
import { ClusterState } from "../state/ChartState";

type Props = {
  clusterState: ClusterState;
};

function ClusterAllInfo({ clusterState }: Props) {
  return (
    <div className="container">
      <div className="columns">
        <div className="column">
          <div className="box p-3">
            <table className="table is-narrow is-fullwidth m-0">
              <thead>
                <tr>
                  <td>검색 결과와 관련된 문서 리스트</td>
                </tr>
              </thead>
              <tbody>
                {clusterState.documentLinks.length === 0 && (
                  <tr>
                    <td>결과 없음</td>
                  </tr>
                )}
                {clusterState.documentLinks.map((i) => (
                  <tr key={i.url}>
                    <td className="is-clickable">
                      <a href={i.url} target="_blank" rel="noreferrer">
                        <span className="is-size-7 mr-1">🔗</span> {i.title}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ClusterAllInfo;
