import { useState } from "react";
import axios from "axios";

import "./Chart.scss";
import { ClusterDatumState, MetadataState } from "../state/ChartState";
import {
  AttributeItem,
  AttributeItemTitleCountValue,
  DocumentItem,
} from "../models/Chart";

type Props = {
  metadataState: MetadataState;
  clusterDatumState: ClusterDatumState;
};

type DocumentState =
  | {
      attributeTitle: string;
      attributeValue: string;
      links: Array<DocumentItem>;
    }
  | undefined;

function ClusterDatumInfo({ metadataState, clusterDatumState }: Props) {
  const [loading, setLoading] = useState(false);
  const [documentState, setDoumentState] = useState<DocumentState>(undefined);

  const searchClusterDocument = async (
    attributeItem: AttributeItem,
    attributeValue: string
  ) => {
    setDoumentState(undefined);

    try {
      setLoading(true);

      const request = {
        dataId: metadataState?.data.id,
        xId: metadataState?.x.id,
        yId: metadataState?.y.id,
        clusterId: clusterDatumState?.datum.id,
        attributeId: attributeItem.id,
        attributeValue: attributeValue,
        query: clusterDatumState?.query,
      };
      const response = await axios.post(
        "/api/clusters/documents/search",
        request
      );
      const documentLinks = response.data.document_links as Array<DocumentItem>;

      const state = {
        attributeTitle: attributeItem.title,
        attributeValue: request.attributeValue,
        links: documentLinks,
      };
      setDoumentState(state);
    } catch (e) {
      alert(
        "상세 키워드 검색 중 오류가 발생하였습니다. 검색 결과를 초기화합니다. 반복적으로 발생 시 운영자에게 문의주세요."
      );
      setDoumentState(undefined);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="columns">
        <div className="column">
          <div className="scrolling-wrapper">
            {clusterDatumState?.attributes.map((i) => {
              return (
                <div key={i.title} className="column">
                  <div className="box p-3">
                    <table className="table is-narrow is-fullwidth m-0">
                      <thead>
                        <tr>
                          <td>{i.title}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {i.values.length === 0 && (
                          <tr>
                            <td>결과 없음</td>
                          </tr>
                        )}
                        {i.values.map((k) => {
                          let valueId = "";
                          let valueCount: number | undefined = undefined;

                          if (i.value_type === "KEYWORD") {
                            const value = k as string;
                            valueId = value;
                            valueCount = undefined;
                          } else {
                            const value = k as AttributeItemTitleCountValue;
                            valueId = value.title;
                            valueCount = value.count;
                          }

                          let trClass = "has-text-link ";
                          if (
                            documentState &&
                            documentState.attributeTitle === i.title &&
                            documentState.attributeValue === k
                          ) {
                            trClass = "is-selected";
                          }

                          return (
                            <tr key={valueId} className={trClass}>
                              <td
                                className="is-clickable"
                                onClick={() =>
                                  searchClusterDocument(i, valueId)
                                }
                              >
                                {valueId}{" "}
                                {valueCount && <span>({valueCount})</span>}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="column is-one-fifths">
          <div className="box p-3">
            <table className="table is-narrow is-fullwidth m-0">
              <thead>
                <tr>
                  <td>선택한 X좌표, Y좌표에서 언급된 문서 리스트</td>
                </tr>
              </thead>
              <tbody>
                {clusterDatumState?.documentLinks.map((i) => {
                  return (
                    <tr key={i.url}>
                      <td className="is-clickable">
                        <a href={i.url} target="_blank" rel="noreferrer">
                          <span className="is-size-7 mr-1">🔗</span> {i.title}
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="column is-one-fifths">
          {loading && (
            <progress className="progress is-medium" max="100">
              Processing
            </progress>
          )}
          {documentState && (
            <div className="card">
              <article className="message">
                <div className="message-header">
                  <p>
                    {documentState.attributeTitle} :{" "}
                    {documentState.attributeValue}
                  </p>
                  <button
                    className="delete"
                    aria-label="delete"
                    onClick={() => setDoumentState(undefined)}
                  ></button>
                </div>
                <div className="message-body">
                  <ul>
                    {documentState.links.map((l) => {
                      return (
                        <li key={l.url} className="keyword-link has-text-link">
                          <a href={l.url} target="_blank" rel="noreferrer">
                            <span className="is-size-7 mr-1">🔗</span> {l.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </article>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ClusterDatumInfo;
