function Loading() {
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content">
        <h1 className="m-3 is-size-3 has-text-white">Processing</h1>
        <progress className="progress is-large is-info" max="100">
          Processing
        </progress>
      </div>
    </div>
  );
}

export default Loading;
