import axios from "axios";
import { useEffect, useState, FormEvent, MouseEvent } from "react";
import {
  ResponsiveScatterPlotCanvas,
  ScatterPlotDatum,
  ScatterPlotRawSerie,
} from "@nivo/scatterplot";
import { ResponsiveLine } from "@nivo/line";
import Markdown from "react-markdown";

import {
  ClusterAttribute,
  ClusterItem,
  Metadata,
  TickItem,
} from "../models/Chart";
import {
  CLUSTER_STATE_DEFAULT,
  ClusterDatumState,
  ClusterDatum,
  ClusterState,
  MetadataState,
  RATIO_STATE_DEFAULT,
  RatioDatum,
  RatioState,
  RatioDatumState,
  Tab,
  LlmState,
} from "../state/ChartState";

import "./Chart.scss";
import Loading from "./Loading";

import ClusterDatumInfo from "./ClusterDatumInfo";
import RatioDatumInfo from "./RatioDatumInfo";
import ClusterAllInfo from "./ClusterAllInfo";

function Chart() {
  // Constants
  const margin = { top: 40, right: 40, bottom: 40, left: 200 };

  const colors = [
    "#FF6633",
    "#FFB399",
    "#00B3E6",
    "#E6B333",
    "#999966",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ];

  // State
  const [metadataState, setMetadataState] = useState<MetadataState>(undefined);
  const [clusterState, setClusterState] = useState<ClusterState>(
    CLUSTER_STATE_DEFAULT
  );
  const [clusterDatumState, setClusterDatumState] =
    useState<ClusterDatumState>(undefined);
  const [ratioState, setRatioState] = useState<RatioState>(RATIO_STATE_DEFAULT);
  const [ratioDatumState, setRatioDatumState] =
    useState<RatioDatumState>(undefined);
  const [llmState, setLlmState] = useState<LlmState>(undefined);

  // UI Control
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [metadataMenu, setMetadataMenu] = useState<string>("");
  const [chartType, setChartType] = useState("SCATTER");

  // UI Function
  const extractTickTitle = (tick: string) => {
    const values = tick.split(",");
    const firstValue = values.at(0) ?? tick;
    const title = firstValue.replaceAll("'", "").replaceAll("(", "");
    if (title.length > 1) {
      return `${title} 외 ${values.length - 1}건 `;
    } else {
      return title;
    }
  };

  // API Function
  const fetchMetadata = async () => {
    const response = (await axios.get<Metadata>("/api/metadata")).data;

    const metadatas = response.metadata;
    const data = metadatas[0];
    const x = data.values[0];
    const y = x.values[0];

    const state = { metadatas, data, x, y };
    setMetadataState(state);

    return state;
  };

  const clearClusterState = () => {
    setClusterDatumState(undefined);
    setRatioState(RATIO_STATE_DEFAULT);
    setRatioDatumState(undefined);
  };

  const handleSearch = async (event: FormEvent) => {
    event.preventDefault();
    searchCluster(metadataState);
  };

  const searchCluster = async (metadataState: MetadataState) => {
    setLlmState(undefined);
    clearClusterState();

    if (metadataState === undefined) {
      return;
    }

    try {
      setLoading(true);

      const request = {
        dataId: metadataState.data.id,
        xId: metadataState.x.id,
        yId: metadataState.y.id,
        query: query.length > 0 ? query : undefined,
      };
      const response = await axios.post("/api/clusters/search", request);

      const clusterData = response.data;
      const state = {
        xTicks: new Map<string, string>(
          clusterData.x_ticks.map((i: TickItem) => [i.tick, i.title])
        ),
        yTicks: new Map<string, string>(
          clusterData.y_ticks.map((i: TickItem) => [i.tick, i.title])
        ),
        clusters: clusterData.clusters.map((i: ClusterItem) => ({
          id: i.id,
          title:
            query.length > 0 && i.doc_count > 0 ? i.doc_count.toString() : "",
          xValue: i.x,
          yValue: i.y,
          x: Number(i.x),
          y: Number(i.y),
          size: i.size,
          docCount: i.doc_count,
        })),
        documentLinks: clusterData.document_links,
        query: query,
        tab: query.length > 0 ? Tab.CLUSTER_ALL : Tab.NONE,
      };
      setClusterState(state);
    } catch (e) {
      alert(
        "데이터 조회 중 오류가 발생하였습니다. 반복적으로 발생 시 운영자에게 문의주세요."
      );
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const searchClusterAttribute = async (clusterDatum: ClusterDatum) => {
    clearClusterState();

    try {
      setLoading(true);

      const request = {
        dataId: metadataState?.data.id,
        xId: metadataState?.x.id,
        yId: metadataState?.y.id,
        clusterId: clusterDatum.id,
        query: query.length > 0 ? query : undefined,
      };
      const response = await axios.post<ClusterAttribute>(
        "/api/clusters/attributes/search",
        request
      );

      const clusterAttribute = response.data;
      setClusterDatumState({
        datum: clusterDatum,
        query: query.length > 0 ? query : undefined,
        documentLinks: clusterAttribute.document_links,
        attributes: clusterAttribute.attributes,
      });
      setClusterState({ ...clusterState, tab: Tab.CLUSTER_DATUM });
    } catch (e) {
      alert(
        "데이터 조회 중 오류가 발생하였습니다. 반복적으로 발생 시 운영자에게 문의주세요."
      );
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRatio = async (event: MouseEvent) => {
    event.preventDefault();
    if (query.length <= 0) {
      return;
    }

    if (clusterState.query !== query) {
      await searchCluster(metadataState);
    }

    try {
      setLoading(true);

      const request = {
        dataId: metadataState?.data.id,
        xId: metadataState?.x.id,
        yId: metadataState?.y.id,
        query: query,
      };
      const response = await axios.post("/api/ratios/search", request);

      const ratioData = response.data;
      const state = {
        xTicks: new Map<string, string>(
          ratioData.x_ticks.map((i: TickItem) => [i.tick, i.title])
        ),
        ratios: ratioData.ratios.map((i: any) => ({
          id: i.id,
          x: i.x,
          y: i.ratio,
          ratio: i.ratio,
        })),
        query: query,
      };
      setRatioState(state);
    } catch (e) {
      alert(
        "데이터 분석 중 오류가 발생하였습니다. 검색 결과를 초기화합니다. 반복적으로 발생 시 운영자에게 문의주세요."
      );
      setRatioState(RATIO_STATE_DEFAULT);
    } finally {
      setLoading(false);
    }
  };

  const searchRatioValue = async (datum: RatioDatum) => {
    setRatioDatumState(undefined);

    try {
      setLoading(true);

      const request = {
        dataId: metadataState?.data.id,
        xId: metadataState?.x.id,
        yId: metadataState?.y.id,
        query: query,
        ratioId: datum.id,
      };
      const response = await axios.post("/api/ratios/values/search", request);

      const state = {
        datum: datum,
        query: query,
        documentLinks: response.data.document_links,
        attributes: response.data.attributes,
      };
      setRatioDatumState(state);
    } catch (e) {
      alert(
        "데이터 조회 중 오류가 발생하였습니다. 반복적으로 발생 시 운영자에게 문의주세요."
      );
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchLlm = async (event: MouseEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      let request: any = {
        dataId: metadataState?.data.id,
        xId: metadataState?.x.id,
        yId: metadataState?.y.id,
      };
      if (query.length > 0) {
        request = {
          dataId: metadataState?.data.id,
          xId: metadataState?.x.id,
          yId: metadataState?.y.id,
          query: query,
        };
      }

      const response = await axios.post("/api/clusters/llm/search", request);
      setLlmState({ message: response.data.llm_response });
    } catch (e) {
      alert(
        "인공지능 분석 중 오류가 발생하였습니다. 인공지능 분석 결과를 초기화합니다. 반복적으로 발생 시 운영자에게 문의주세요."
      );
      setLlmState(undefined);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMetadata().then((v) => {
      searchCluster(v);
    });
    // eslint-disable-next-line
  }, []);

  let chartData: ScatterPlotRawSerie<ScatterPlotDatum>[] = [];
  if (chartType === "SCATTER") {
    chartData = [
      {
        id: "Cluster",
        data: clusterState.clusters,
      },
    ];
  } else {
    chartData = Array.from(clusterState.yTicks.keys()).map((y) => {
      const data = clusterState.clusters.filter((d) => d.y.toString() === y);
      return {
        id: y + "_" + extractTickTitle(clusterState.yTicks.get(y) ?? ""),
        data: data.map((d) => ({ id: d.id, x: d.x, y: d.docCount, raw: d })),
      };
    });
  }

  return (
    <div>
      {loading && <Loading />}
      <div className="field is-grouped mb-0 pb-0">
        <div className="field mr-4">
          <div className="control">
            <div
              className={
                "dropdown " + (metadataMenu === "dataName" ? "is-active" : "")
              }
            >
              <div className="dropdown-trigger">
                <button
                  className="button"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  onClick={() => setMetadataMenu("dataName")}
                >
                  <span className="icon is-small">
                    <i className="fa-solid fa-database"></i>
                  </span>
                  <span>DATA: {metadataState?.data.title}</span>
                </button>
              </div>
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  {metadataState?.metadatas.map((i) => {
                    return (
                      <a
                        key={`data:${i.id}`}
                        href="#!"
                        className={
                          "dropdown-item " +
                          (i.id === metadataState.data.id ? "is-active" : "")
                        }
                        onClick={() => {
                          setMetadataMenu("");

                          const state = {
                            metadatas: metadataState.metadatas,
                            data: i,
                            x: i.values[0],
                            y: i.values[0].values[0],
                          };
                          setMetadataState(state);
                          searchCluster(state);
                        }}
                      >
                        {i.title}
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field mr-4">
          <div className="control">
            <div
              className={
                "dropdown " + (metadataMenu === "xName" ? "is-active" : "")
              }
            >
              <div className="dropdown-trigger">
                <button
                  className="button"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  onClick={() => setMetadataMenu("xName")}
                >
                  <span className="icon is-small">
                    <i className="fa-solid fa-list"></i>
                  </span>
                  <span>{metadataState?.x.title}</span>
                </button>
              </div>
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  {metadataState?.data.values.map((i) => {
                    return (
                      <a
                        key={`data:${metadataState.data.id}:x:${i.id}`}
                        href="#!"
                        className={
                          "dropdown-item " +
                          (i.id === metadataState.x.id ? "is-active" : "")
                        }
                        onClick={() => {
                          setMetadataMenu("");

                          const state = {
                            metadatas: metadataState.metadatas,
                            data: metadataState.data,
                            x: i,
                            y: i.values[0],
                          };
                          setMetadataState(state);
                          searchCluster(state);
                        }}
                      >
                        {i.title}
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field mr-4">
          <div className="control">
            <div
              className={
                "dropdown " + (metadataMenu === "yName" ? "is-active" : "")
              }
            >
              <div className="dropdown-trigger">
                <button
                  className="button"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  onClick={() => setMetadataMenu("yName")}
                >
                  <span className="icon is-small">
                    <i className="fa-solid fa-list"></i>
                  </span>
                  <span>{metadataState?.y.title}</span>
                </button>
              </div>
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  {metadataState?.x.values.map((i) => {
                    return (
                      <a
                        key={`data:${metadataState.data.id}:x:${metadataState.x.id}:y:${i.id}`}
                        href="#!"
                        className={
                          "dropdown-item " +
                          (i.id === metadataState.y.id ? "is-active" : "")
                        }
                        onClick={() => {
                          setMetadataMenu("");

                          const state = {
                            metadatas: metadataState.metadatas,
                            data: metadataState.data,
                            x: metadataState.x,
                            y: i,
                          };
                          setMetadataState(state);
                          searchCluster(state);
                        }}
                      >
                        {i.title}
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSearch}>
        <div className="field is-grouped">
          <div className="field has-addons">
            <div className="control keyword-input">
              <input
                className="input"
                type="text"
                placeholder="검색어"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div className="control">
              <button type="submit" className="button is-info">
                전체 검색
              </button>
            </div>
          </div>
          <div className="field ml-3">
            <button
              type="button"
              className="button is-info"
              onClick={handleSearchLlm}
            >
              인공지능 분석
            </button>
          </div>
          <div className="field ml-3">
            <button
              type="button"
              className="button is-primary"
              onClick={handleSearchRatio}
              disabled={query === ""}
            >
              {metadataState?.x.title} 에 따른 검색어의 중요도 흐름 계산
            </button>
          </div>
        </div>
      </form>
      <div className="p-2">
        {metadataState?.data.kibana_link && (
          <span>
            Go to Additional Information Analysis:
            <a
              href={metadataState?.data.kibana_link}
              target="kibana_link"
              className="ml-1"
            >
              {metadataState?.data.kibana_link}
            </a>
          </span>
        )}
      </div>

      <nav className="panel">
        <div className="panel-heading">
          {clusterState && clusterDatumState && (
            <div className="content is-size-5 has-text-weight-normal">
              <div className="wrap">
                X:{" "}
                {clusterState.xTicks.get(clusterDatumState.datum.x.toString())}{" "}
              </div>
              <div className="wrap mt-1">
                Y:{" "}
                {clusterState.yTicks.get(clusterDatumState.datum.y.toString())}
              </div>
            </div>
          )}
        </div>
        <div className="panel-block">
          <div className="control">
            <div className="tabs is-size-7">
              <ul>
                <li className={chartType === "SCATTER" ? "is-active" : ""}>
                  <a href="#!" onClick={() => setChartType("SCATTER")}>
                    Scatter
                  </a>
                </li>
                <li className={chartType === "LINE" ? "is-active" : ""}>
                  <a href="#!" onClick={() => setChartType("LINE")}>
                    Line
                  </a>
                </li>
              </ul>
            </div>
            <div id="infori-plot">
              {chartType === "SCATTER" && (
                <ResponsiveScatterPlotCanvas
                  data={chartData}
                  margin={margin}
                  axisBottom={{
                    legend: "<" + metadataState?.x.title + ">",
                    legendPosition: "middle",
                    legendOffset: 35,
                    format: (v) => clusterState.xTicks.get(v.toString()) ?? v,
                  }}
                  axisLeft={{
                    legend: "<" + metadataState?.y.title + ">",
                    legendPosition: "middle",
                    legendOffset: -190,
                    format: (v: any) => {
                      const yTick = clusterState.yTicks.get(v.toString());
                      if (yTick === undefined) {
                        return v;
                      }

                      return extractTickTitle(yTick);
                    },
                  }}
                  renderNode={(ctx, node) => {
                    const data = node.data as ClusterDatum;

                    let pointSize = Math.ceil(data.size / 2.3);
                    if (pointSize > 0) {
                      pointSize = Math.max(pointSize, 3);
                    }

                    let color = colors[Math.floor(Number(data.y))];
                    ctx.beginPath();
                    ctx.arc(node.x, node.y, pointSize, 0, 2 * Math.PI);
                    ctx.fillStyle = color;
                    ctx.globalAlpha = 0.7;
                    ctx.fill();

                    if (data.title && data.title !== "") {
                      ctx.font = "14px Arial";
                      ctx.fillStyle = "black";
                      ctx.fillText(
                        data.title,
                        node.x - 5 + (4 - data.title.length * 3),
                        node.y + 5
                      );
                    }
                  }}
                  layers={["grid", "axes", "nodes", "mesh", "annotations"]}
                  tooltip={(props) => {
                    const datum = props.node.data as ClusterDatum;
                    return (
                      <article className="message is-success cluster-tooltip">
                        <div className="message-header p-1">
                          <span className="mx-2">
                            정보 (문서: {datum.docCount} 건)
                          </span>
                        </div>
                        <div className="message-body p-2">
                          <ul className="is-size-7">
                            <li>
                              - {metadataState?.x.title}:{" "}
                              {clusterState.xTicks.get(datum.xValue)}
                            </li>
                            <li>
                              - {metadataState?.y.title}:{" "}
                              {clusterState.yTicks.get(datum.yValue)}
                            </li>
                            <li>
                              - X: {datum.x}, Y: {datum.y}
                            </li>
                          </ul>
                        </div>
                      </article>
                    );
                  }}
                  onClick={async (event) => {
                    const datum = event.data as ClusterDatum;
                    await searchClusterAttribute(datum);
                  }}
                />
              )}
              {chartType === "LINE" && (
                <ResponsiveLine
                  data={chartData}
                  margin={{ top: 50, right: 200, bottom: 50, left: 100 }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: "auto",
                    max: "auto",
                    stacked: false,
                    reverse: false,
                  }}
                  axisBottom={{
                    legend: "<" + metadataState?.x.title + ">",
                    legendPosition: "middle",
                    legendOffset: 35,
                    format: (v) => clusterState.xTicks.get(v.toString()) ?? v,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Document count",
                    legendOffset: -80,
                    legendPosition: "middle",
                  }}
                  legends={[
                    {
                      anchor: "bottom-right",
                      direction: "column",
                      justify: false,
                      translateX: 100,
                      translateY: 0,
                      itemsSpacing: 0,
                      itemDirection: "left-to-right",
                      itemWidth: 80,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: "circle",
                      symbolBorderColor: "rgba(0, 0, 0, .5)",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemBackground: "rgba(0, 0, 0, .03)",
                            itemOpacity: 1,
                          },
                        },
                      ],
                      toggleSerie: true,
                    },
                  ]}
                  pointSize={10}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor" }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  tooltip={(props) => {
                    const datum = (props.point.data as any).raw as ClusterDatum;
                    return (
                      <article className="message is-success cluster-tooltip">
                        <div className="message-header p-1">
                          <span className="mx-2">
                            정보 (문서: {datum.docCount} 건)
                          </span>
                        </div>
                        <div className="message-body p-2">
                          <ul className="is-size-7">
                            <li>
                              - {metadataState?.x.title}:{" "}
                              {clusterState.xTicks.get(datum.xValue)}
                            </li>
                            <li>
                              - {metadataState?.y.title}:{" "}
                              {clusterState.yTicks.get(datum.yValue)}
                            </li>
                            <li>
                              - X: {datum.x}, Y: {datum.y}
                            </li>
                          </ul>
                        </div>
                      </article>
                    );
                  }}
                  onClick={async (event) => {
                    const datum = (event.data as any).raw as ClusterDatum;
                    await searchClusterAttribute(datum);
                  }}
                />
              )}
            </div>

            {llmState && (
              <article className="message m-5">
                <div className="message-header">
                  <p>인공지능 분석</p>
                </div>
                <div className="message-body ai-message">
                  <Markdown
                    components={{
                      a: (props) => (
                        <a href={props.href}>
                          [{props.children}]({props.href})
                        </a>
                      ),
                    }}
                  >
                    {llmState.message}
                  </Markdown>
                </div>
              </article>
            )}

            {clusterState.tab !== Tab.NONE && (
              <div className="container m-5">
                <div className="tabs wrap">
                  <ul>
                    <li
                      className={
                        clusterState.tab === Tab.CLUSTER_ALL ? "is-active" : ""
                      }
                    >
                      <a
                        href="#!"
                        className="wrap"
                        onClick={() => {
                          setClusterState({
                            ...clusterState,
                            tab: Tab.CLUSTER_ALL,
                          });
                        }}
                      >
                        전체 검색 결과{" "}
                        {query && (
                          <span className="ml-1">(검색어: {query})</span>
                        )}
                      </a>
                    </li>
                    {clusterDatumState && (
                      <li
                        className={
                          clusterState.tab === Tab.CLUSTER_DATUM
                            ? "is-active"
                            : ""
                        }
                      >
                        <a
                          href="#!"
                          className="wrap"
                          onClick={() => {
                            setClusterState({
                              ...clusterState,
                              tab: Tab.CLUSTER_DATUM,
                            });
                          }}
                        >
                          상세정보 (X:{" "}
                          {clusterState.xTicks.get(
                            clusterDatumState.datum.x.toString()
                          )}{" "}
                          & Y:{" "}
                          {extractTickTitle(
                            clusterState.yTicks.get(
                              clusterDatumState.datum.y.toString()
                            ) ?? ""
                          )}
                          )
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
                {clusterState.tab === Tab.CLUSTER_ALL && (
                  <ClusterAllInfo clusterState={clusterState}></ClusterAllInfo>
                )}
                {clusterState.tab === Tab.CLUSTER_DATUM && (
                  <ClusterDatumInfo
                    metadataState={metadataState}
                    clusterDatumState={clusterDatumState}
                  ></ClusterDatumInfo>
                )}
              </div>
            )}
          </div>
        </div>
      </nav>

      {ratioState.ratios.length > 0 && (
        <nav className="panel">
          <p className="panel-heading">
            {metadataState?.x.title}에 따른 검색어의 비중
            <span className="subtitle is-7 ml-3">({query})</span>
          </p>
          <div className="panel-block">
            <div className="control">
              <div id="period-ratio-plot">
                <ResponsiveLine
                  data={[
                    {
                      id: "X Ratio",
                      data: ratioState.ratios,
                    },
                  ]}
                  margin={margin}
                  useMesh={true}
                  axisBottom={{
                    legend: "<" + metadataState?.x.title + ">",
                    legendPosition: "middle",
                    legendOffset: 35,
                    format: (v) => clusterState.xTicks.get(v.toString()) ?? v,
                  }}
                  tooltip={(props) => {
                    return (
                      <article className="message is-success">
                        <div className="message-body p-2">
                          <ul className="ml-1 is-size-7">
                            <li>
                              - {metadataState?.x.title}:{" "}
                              {clusterState.xTicks.get(
                                props.point.data.xFormatted.toString()
                              )}
                            </li>
                            <li>- Ratio: {props.point.data.yFormatted}</li>
                            <li>
                              - X: {props.point.data.xFormatted}, Y:{" "}
                              {props.point.data.yFormatted}
                            </li>
                          </ul>
                        </div>
                      </article>
                    );
                  }}
                  onClick={async (event: any) => {
                    const datum = event.data as RatioDatum;
                    await searchRatioValue(datum);
                  }}
                ></ResponsiveLine>
              </div>
              {ratioDatumState && (
                <div className="container m-5">
                  <div className="tabs">
                    <ul>
                      <li className="is-active">
                        <a href="#!">
                          {metadataState?.x.title}:{" "}
                          {clusterState.xTicks.get(ratioDatumState.datum.x) ??
                            ""}{" "}
                          & Ratio 내 상세정보
                          <span className="ml-1">(검색어: {query})</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <RatioDatumInfo
                    metadataState={metadataState}
                    ratioDatumState={ratioDatumState}
                  ></RatioDatumInfo>
                </div>
              )}
            </div>
          </div>
        </nav>
      )}

      <div className="is-size-7 has-text-grey">
        <div className="content has-text-centered">
          <p>
            Copyright 2022.{" "}
            <a
              href="https://inforience.net"
              className="has-text-grey has-text-weight-bold"
            >
              Inforience
            </a>{" "}
            Inc. all rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Chart;
